// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-posts-css-flex-index-mdx": () => import("./../../../src/posts/css/flex/index.mdx" /* webpackChunkName: "component---src-posts-css-flex-index-mdx" */),
  "component---src-posts-css-grid-index-mdx": () => import("./../../../src/posts/css/grid/index.mdx" /* webpackChunkName: "component---src-posts-css-grid-index-mdx" */),
  "component---src-posts-javascript-tsc-express-mysql-index-mdx": () => import("./../../../src/posts/javascript/tsc-express-mysql/index.mdx" /* webpackChunkName: "component---src-posts-javascript-tsc-express-mysql-index-mdx" */)
}

